@use 'sass:map';
@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import "./toast";

.buttons{
  width:150px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: lightgray; }
.content {
   margin: 0px 25px 0px 25px;
   font-family: Roboto, "Helvetica Neue", sans-serif; background-color: lightgray;
}
